import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import { ContentSection } from '@digigear/elements';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

const NotFoundPage = () => {
  useEffect(() => {
    navigate('/');
  }, []);

  return (
    <Layout>
      <Seo title="404 Not found" />
      <ContentSection>
        <h1>404 Not Found</h1>
      </ContentSection>
    </Layout>
  );
};

export default NotFoundPage;
